import { TApolloClient } from 'src/types/apollo'
import { CartItemSource } from 'src/generated/graphql'
import { addToCartRepository } from '../repository/AddToCart'
import { CartActionResult } from '@concepts/Cart/types/actions'
import { buildError } from '../utils/buildError'

type AddItemParams = {
  saleId: number
  quantity?: number
  childSaleId: number
  bidPrice?: number
  priceToken?: string
  maxPerUser: number
  source: CartItemSource
  encryptedCartId?: string
  client: TApolloClient
}

const add = async ({
  saleId,
  quantity = 1,
  childSaleId,
  bidPrice,
  priceToken,
  maxPerUser,
  source,
  encryptedCartId,
  client
}: AddItemParams): Promise<CartActionResult> => {
  try {
    const cartItem = await addToCartRepository(
      {
        childSaleId,
        saleId,
        quantity,
        bidPrice: bidPrice || undefined,
        priceToken: priceToken || undefined,
        maxPerUser,
        source
      },
      client,
      encryptedCartId
    )

    return cartItem
  } catch (error) {
    return Promise.reject(buildError(error as object))
  }
}

export default { add }

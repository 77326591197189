import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CartItemSource, Sale } from 'src/generated/graphql'

import Link from '@atoms/UILink/Link'
import Currency from '@atoms/UICurrency/Currency'
import Button from '@atoms/UIButton/Button'

import upsellAnalytics from '@lib/gtm/upsellAnalytics'
import { fetchCartSaleImage } from '@utils/fetchSaleImage'
import { isPresent } from '@utils/logic'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import {
  PUBLISHER,
  SSR_CART_UPSELLS_LINK
} from '@concepts/FeatureFlag/utils/constants'
import { truncate } from '@utils/strings'
import { Box, Flex, Image, Text } from '@lib/UIComponents'

const styles = {
  title: {
    fontSize: 1,
    ml: 1,
    color: 'blacks.400'
  },
  container: {
    backgroundColor: 'rgba(236, 242, 255, 0.40)',
    borderTop: '1px solid',
    borderColor: 'grays.100',
    px: 5,
    pt: 5,
    pb: 2
  },
  image: {
    borderRadius: 'default',
    mt: 1
  },
  textDescriptionBox: {
    ml: 3,
    minHeight: '41px'
  },
  name: {
    flexShrink: 1,
    flexGrow: 0,
    maxWidth: '240px',
    color: 'blacks.400',
    fontWeight: 'bold'
  },
  swiper: {
    overflow: 'hidden',
    paddingTop: '26px',
    marginTop: '-25px',
    '.swiper': {
      overflow: 'visible',
      zIndex: 0
    },
    '.swiper-slide': {
      display: 'flex'
    },
    '.swiper-button-prev, .swiper-button-next': {
      top: '3px',
      fontWeight: 'bold',
      width: '18px',
      height: '18px',
      border: '1px solid',
      borderColor: 'grays.100',
      boxSizing: 'border-box',
      color: 'black',
      opacity: 1,
      ':after': {
        fontSize: ['0.6rem'],
        textRendering: 'auto'
      },
      '&.swiper-button-disabled': {
        ':after': {
          opacity: 0.5
        }
      }
    },
    '.swiper-button-prev': {
      left: 'auto',
      borderRadius: '50px 0px 0px 50px',
      right: '17px'
    },
    '.swiper-button-next': {
      left: 'auto',
      borderRadius: '0px 50px 50px 0px',
      borderLeft: '0',
      right: '0'
    }
  }
}

type Props = {
  upsells: Array<Sale>
  addToCart: Function
}

const Upsell: React.FC<React.PropsWithChildren<Props>> = ({
  upsells,
  addToCart
}) => {
  const featureFlag = useFeatureFlag()

  const isUpsellLinkEnabled = featureFlag.isEnabled(
    SSR_CART_UPSELLS_LINK,
    PUBLISHER
  )

  if (!isPresent(upsells)) return null

  const saleName = (sale: Sale) => {
    return truncate(sale.name, 60)
  }

  return (
    <Box sx={styles.container}>
      <Text sx={styles.title}>Customers Also Bought:</Text>
      <Box sx={styles.swiper}>
        <Swiper
          modules={[Navigation, A11y]}
          slidesPerView={1}
          spaceBetween={20}
          navigation
          autoHeight
        >
          {upsells.map((sale) => (
            <SwiperSlide key={sale.databaseId}>
              <Flex sx={{ width: '100%', p: 1 }}>
                <Box>
                  <Image
                    sx={styles.image}
                    alt={saleName(sale)}
                    src={fetchCartSaleImage(sale)}
                  />
                </Box>

                <Flex sx={{ width: '100%', flexDirection: 'column' }}>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      ml: 4,
                      minHeight: '41px'
                    }}
                  >
                    <>
                      {isUpsellLinkEnabled && isPresent(sale.slug) ? (
                        <Link
                          to={`/sales/${sale.slug}`}
                          sx={styles.name}
                          onClick={() => {
                            upsellAnalytics.clickedCartUpsellSalePageLink(
                              sale.id
                            )
                          }}
                        >
                          {saleName(sale)}
                        </Link>
                      ) : (
                        <Text sx={styles.name}>{saleName(sale)}</Text>
                      )}

                      <Flex sx={{ mt: 2, gap: 4 }}>
                        {sale.priceInCents > sale.priceWithDiscountInCents && (
                          <Currency
                            sx={{
                              textDecorationLine: 'line-through',
                              color: 'grays.6'
                            }}
                          >
                            {sale.priceInCents}
                          </Currency>
                        )}
                        <Currency>{sale.priceWithDiscountInCents}</Currency>
                      </Flex>
                    </>
                  </Flex>

                  <Flex sx={{ justifyContent: 'flex-end', mt: '-5px' }}>
                    <Button
                      variant="tertiary"
                      onClick={() =>
                        addToCart(
                          Number(sale.databaseId),
                          CartItemSource.UpsellMinicart
                        )
                      }
                      size="small"
                    >
                      Add To Cart
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default Upsell

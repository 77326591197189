import Header from '@organisms/Header/Header'
import Main from '@organisms/Main/Main'
import Footer from '@organisms/Footer/Footer'
import CartContextProvider from '@concepts/Cart/store/Provider'
import CartPopupProvider from '@concepts/Cart/store/CartPopupProvider'
import { UIStyles } from '@lib/UIComponents/types'
import { Flex } from '@lib/UIComponents'

type Props = {
  useContainer?: boolean
  useHelloBar?: boolean
  sx?: UIStyles
  footerSx?: UIStyles
  children?: React.ReactNode
}

const Layout = ({
  children,
  useHelloBar = true,
  useContainer = true,
  sx = {},
  footerSx = {}
}: Props) => (
  <CartContextProvider>
    <CartPopupProvider>
      <Flex
        sx={{
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Header useHelloBar={useHelloBar} />
        <Main sx={sx} useContainer={useContainer}>
          {children}
        </Main>
        <Footer sx={footerSx} />
      </Flex>
    </CartPopupProvider>
  </CartContextProvider>
)

export default Layout

import segmentAnalytics from '@lib/segment/analytics'
import { CartActionResult } from '@concepts/Cart/types/actions'

type Params = {
  operationResult: CartActionResult
  hostname: string | undefined
}

const productAddedToCartAnalytics = ({
  operationResult,
  hostname
}: Params): void => {
  if (operationResult.cartItem && hostname) {
    segmentAnalytics.productAdded({
      cartItem: operationResult.cartItem,
      cartId: operationResult.cartItem.cartId,
      hostname
    })
  }
}

export { productAddedToCartAnalytics }
